<script setup>
 const { anchorId, headline, copy, button, merchandise } = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  headline: {
   type: String,
   required: true,
  },
  copy: {
   type: String,
   default: '',
   required: false,
  },
  button: {
   type: Object,
  },
  merchandise: {
   type: Array,
   required: true,
  },
 });
</script>

<template>
 <section :id="anchorId" class="block block-design-store container bg-white">
  <div class="grid">
   <ProjectTitle v-if="headline" :title="headline" class="item-1" />
  </div>
  <div class="grid items-wrapper" v-aos>
   <div class="item-2">
    <MessHtml v-if="copy" :html="copy" tag="div" class="copy rich-text" />
    <ProjectButton v-if="button" :label="button.label" :link="button.url" :title="button.title" class="red" />
   </div>

   <FragmentsCardStore
    v-if="merchandise.length"
    v-for="(merch, index) in merchandise.slice(0, 2)"
    :card="merch"
    :class="`item-${index + 3}`"
    v-aos
    :data-position="index + 1"
   />
  </div>
 </section>
</template>

<style lang="scss">
 .block-design-store {
  &.block {
   padding: 0 0 6rem;
   overflow: visible;
  }
  .title-wrapper {
   max-width: 579px;
   margin: -2.5rem 0 0 0;
   transform: translateY(2rem);
   transition: 350ms ease-in-out;

   @media (min-width: $mobile) {
    margin: -3rem 0 0 0;
   }
   @media (min-width: $tablet) {
    margin: -3.75rem 0 0 0;
   }
  }
  .item-2 {
   transition: var(--card-reveal);
   .copy {
    width: 350px;
    margin-bottom: 2rem;
   }
  }

  .items-wrapper {
   overflow: hidden;
  }

  .grid {
   .item-1 {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
   }
   .item-2 {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding: 23px 0 46px;
   }

   .item-3 {
    grid-column: 1 / -1;
   }
   .item-4 {
    grid-column: 1 / -1;
   }

   @media (min-width: 600px) and (max-width: $mobile) {
    .item-3 {
     width: 50%;
     justify-self: end;
    }
    .item-4 {
     width: 50%;
     justify-self: start;
    }
   }
   @media (min-width: $mobile) {
    .item-1 {
     grid-column: 1 / 8;
    }
    .item-2 {
     grid-column: 1 / 8;
     grid-row: 2 / 3;
    }
    .item-3 {
     grid-column: 5 / 9;
     margin-top: -1.5rem;
     grid-row: 2 / 4;
    }
    .item-4 {
     grid-column: 1 / 5;
     grid-row: 3 / span 2;
    }
   }

   @media (min-width: $tablet) {
    .item-1 {
     grid-column: 2 / span 7;
    }
    .item-2 {
     grid-column: 2 / span 7;
    }
    .item-3 {
     grid-column: 8 / 12;
    }
    .item-4 {
     grid-column: 2 / 6;
    }
   }
  }

  .item-2 {
   clip-path: inset(0 100% 0 0);
  }

  .v-aos-before-enter {
   .item-2 {
    clip-path: inset(0 100% 0 0);
    opacity: 0;
   }
  }
  .v-aos-entered {
   .title-wrapper {
    transform: translateY(0);
   }

   .item-2 {
    clip-path: inset(0 0 0 0);
    opacity: 1;
   }
  }
 }
</style>
